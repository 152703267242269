<template>
  <UserDetailItemEditCard confirmation-message="Functie werd succesvol gewijzigd"
                      title="Bewerk functie"
                      :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                      update-store-action="usersModule/updateJobTitle"
                      :update-store-action-payload="{jobTitleDTO: this.jobTitleDTO, userId: this.userId}"
                      :userId="userId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field persistent-placeholder label="Functie" type="text" class="min-w-100"
                        v-model="jobTitleDTO.jobTitle" dense/>
        </v-col>
      </v-row>
    </template>
  </UserDetailItemEditCard>
</template>

<script>

import routeNames from "@/router/RouteNames";
import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"

export default {
  name: "UserDetailHeaderEditJobTitle.vue",
  components: {
    UserDetailItemEditCard
  },
  props: {
    userId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      routeNames: routeNames,
      jobTitleDTO: {},
      promises: [
        this.$store.dispatch('usersModule/fetchJobTitle', this.userId)
          .then((result) => this.jobTitleDTO = result)
      ]
    }
  },
}
</script>
